import React, { useState,useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import "./calendar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock,faAngleLeft,faAngleRight, faGlobe, faHome} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'; 

const ActualCal = ({  setDay, month, setMonth, year, setYear,date,setDate,timezones,setTimezones,selectedTimeZone,setSelectedTimeZone,setpassChosen,selectedDay,setSelectedDay }) => {
  const currentDate =new Date() ;
//  const month = date.toLocaleString('default', { month: 'long' });
  const originalTimes = ['9:30', '11:30','8:00'];
  const [adjustedTimes, setAdjustedTimes] = useState([]);
  
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const isStateUpdated = useRef(false);
  const handleDayClick = (dayIndex,day) => {
    if (dayIndex === 0 || dayIndex === 4|| dayIndex === 5 || dayIndex === 6) {
      setSelectedDay(day);   
      setDay(day)
    }   
  };
 

  const calculateAdjustedTimes = () => {
    if (selectedTimeZone) {
      // Extract the offset from the selected time zone label
      const selectedTimeZoneOffset = parseFloat(selectedTimeZone.label.match(/-?\d+/)[0]);
  
      // Determine the sign of the offset (+ or -)
      const sign = selectedTimeZone.label.includes('-') ? -1 : 1;
    
      // Adjust the offset to get the difference in hours
      const selectedTimeZoneHoursDifference = sign * selectedTimeZoneOffset - 3; // Difference between UTC+3 and the selected time zone
  
      const adjustedTimes = originalTimes.map((time) => {
        const [hours, minutes] = time.split(':');
        let adjustedHours = parseInt(hours) + selectedTimeZoneHoursDifference;
  
        // Ensure adjusted hours are within the range 0-23
        adjustedHours = (adjustedHours + 24) % 24;
  
        return `${adjustedHours.toString().padStart(2, '0')}:${minutes}`;
      });
  
      setAdjustedTimes(adjustedTimes);
    }
  };
  
 // const year = date.getFullYear();
 
 
  const getFirstDay = (month,year) => {
    // Iterate over each day of the month
    const today = new Date();
    

    for (let day = 1; day <= 31; day++) {
      const currentDate = new Date(year, month, day);
      const currentDay = currentDate.getDay();
      const isFutureDay = new Date(year, month, currentDate.getDate()) >= today;
      // Check if the day is Sunday, Thursday, Friday, or Saturday
      if (isFutureDay && (currentDay === 0 || currentDay === 4 || currentDay === 5 || currentDay === 6)) {
      
        return currentDate.getDate();
      }
    }
  };
  const prevMonth = () => {

    const prevMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const prevMonth = prevMonthDate.getMonth(); // Zero-based index (0 for January, 1 for February, and so on)
    const prevYear = prevMonthDate.getFullYear(); 
    let x = getFirstDay(prevMonth,prevYear)
    setSelectedDay(x);
    setDay(x);
    setDate(prevMonthDate);
    setMonth(monthNames[prevMonth]);
    setYear(prevYear);
  };

  const nextMonth = () => {
    const nextMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    const nextMonth = nextMonthDate.getMonth(); // Zero-based index (0 for January, 1 for February, and so on)
    const nextYear = nextMonthDate.getFullYear(); // Four-digit year format
    let x = getFirstDay(nextMonth,nextYear)
    setSelectedDay(x);
    setDay(x);
    setDate(nextMonthDate);
    setMonth(monthNames[nextMonth]);
    setYear(nextYear);
  };
 
  const getDaysInMonth = () => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const totalDays = new Date(year, month + 1, 0).getDate();
    const firstDayIndex = new Date(year, month, 1).getDay();
    const days = [];

    for (let i = 1; i <= totalDays; i++) {
      days.push(i);
    }

    for (let i = 0; i < firstDayIndex; i++) {
      days.unshift(null);
    }
    return days;
  };
  const [daysInMonth,setDaysInMonth] = useState(getDaysInMonth());
  useEffect(() => {
    calculateAdjustedTimes();
  }, [selectedTimeZone]);
  useEffect(() => {
    setDaysInMonth(getDaysInMonth());
   
  }, [date]);

  useEffect(() => {
    const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZoneExists = timezones.find(zone => zone.label === defaultTimeZone);

    if (!timeZoneExists) {
      const offset = new Date().getTimezoneOffset() / -60;
      const newTimeZone = {
        value: `timezone${timezones.length + 1}`,
        label: `UTC ${offset >= 0 ? `+${offset}` : offset}:00 (${defaultTimeZone})`
      };
      setTimezones([...timezones, newTimeZone]);
      setSelectedTimeZone(newTimeZone);
    } else {
      setSelectedTimeZone(timeZoneExists);
    }
  }, []);

  const getDayColor = (dayIndex,day) => {
    const today = new Date();
    const isFutureDay = new Date(date.getFullYear(), date.getMonth(), day) >= today;
    if (day === currentDate.getDate()) {
      return 'green';
    } 
    if (isFutureDay && (dayIndex === 0 || dayIndex === 4 || dayIndex === 5 || dayIndex === 6) ) { 
      if (!isStateUpdated.current && day !=null) {
         setSelectedDay(day);  
         setDay(day) 
         isStateUpdated.current = true;
      }
      
      return 'yellow';
    }
    else {
      return 'inherit';
    }
    
  };
  
  const getClassName = (dayIndex, day) => {
    const color = getDayColor(dayIndex,day);
    if (color === 'yellow' && selectedDay != null ) {
      return selectedDay === day ? `${color}-selected-day` : `${color}-clickable-day`;
    } 
    else {
      // console.log(color)
      return color;
    }
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      width:'75vw',
      maxWidth:'300px',
      marginLeft: '1px',
      border: 'none',
      color:  '#ffffff',
      backgroundColor:'rgba(255, 255, 255, .2)',
      boxShadow: state.isFocused ? '0 0 0 2px none' : 'none',
      '&:hover': {
        border: 'none',
        background: '#ffffff7b',
        boxShadow: state.isFocused ? '0 0 0 2px none' : 'none',
      },
    }),
    option: (base, { isFocused }) => ({
      ...base,
      color: isFocused ? 'rgb(4,35,46)' : '#ffffff',
      backgroundColor: isFocused ? 'rgba(21, 21, 21, 0.17)' : 'rgba(4, 35, 46, 0.519)',
      cursor: 'pointer',
    }),
   
  };
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowHeader(window.innerWidth > 981);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [selectedButton, setSelectedButton] = useState("button1");;

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    if(buttonId==="button1"){
      setpassChosen(originalTimes[0])
    }else if(buttonId==="button2"){
      setpassChosen(originalTimes[1])
    }
    else if(buttonId==="button3"){
      setpassChosen(originalTimes[2])
    }
  };


  return (
    <>
    {showHeader && (
        <h1 className="dateTime">Select Date & Time</h1>
      )}
    <div className="calendarContainer">
    <div className="calendar">     
      <div className="calendar-header">
        <button onClick={prevMonth} id="mrButton">
          <FontAwesomeIcon icon={faAngleLeft} className="icon" />
        </button>
        <h2>{month} {year}</h2>
        <button onClick={nextMonth} id="mrButton">
          <FontAwesomeIcon icon={faAngleRight} className="icon" />
        </button>
      </div>
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Sun</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
            <th>Sat</th>
          </tr>
        </thead>
        <tbody>
  {Array.from({ length: Math.ceil(daysInMonth.length / 7) }).map((_, rowIndex) => (
    <tr key={rowIndex}>
      {Array.from({ length: 7 }).map((_, colIndex) => {
        const index = rowIndex * 7 + colIndex;
        const day = daysInMonth[index];
        const isCurrentMonth = day !== null;
        const className = isCurrentMonth ? `current-month-day-${getDayColor(colIndex,day)}` : 'other-month-day';
        return (
          <td
          key={colIndex} 
          className={getClassName(colIndex, day)}
          id={className}
          onClick={() => handleDayClick(colIndex,day)}>
               {day}
          </td>
        );
      })}
    </tr>
  ))}
   </tbody>
      </table>
    </div>
    <div className="button-container">
          <button id="timeButton"   onClick={() => handleButtonClick("button1")}  
          className={selectedButton === "button1" ? 'selectedCalendar' : ''}>{adjustedTimes[0]}</button>
          <button id="timeButton" onClick={() => handleButtonClick("button2")}  
          className={selectedButton === "button2" ? 'selectedCalendar' : ''}>{adjustedTimes[1]}</button>
          <button id="timeButton" onClick={() => handleButtonClick("button3")}  
          className={selectedButton === "button3" ? 'selectedCalendar' : ''}>{adjustedTimes[2]}</button>
    </div>  
    </div>
    <h1 className="dateTime">Time zone</h1>
    <div>
    </div>
    <div className="timezoneGrp">
    <FontAwesomeIcon icon={faGlobe} className="globe"/>
      <Select options={timezones} value={selectedTimeZone} 
        onChange={setSelectedTimeZone} styles={customStyles}/>
      </div> 
    </>
  );
};
const FullCalendar = () => {
  const [day,setDay]=useState(new Date());
  const [date, setDate] = useState(new Date());
  const day2 = date.getDate(); 
  const [passChosen, setpassChosen] = useState("9:30");
  const [month,setMonth]=useState(date.toLocaleString('default', { month: 'long' }));
  const [year,setYear]=useState(date.getFullYear());
  const [selectedDay, setSelectedDay] = useState(day2);
  const [timezones, setTimezones] = useState([
    {value:'timezone1',label:  "UTC - 12:00 (International Date Line West)"},
    {value:"timezone2",label:  "UTC - 11:00 (Samoa Standard Time)"},
    {value:'timezone3',label:  "UTC - 10:00 (Hawaii-Aleutian Standard Time)"},
    {value:"timezone4",label:  "UTC - 9:00 (Alaska Standard Time)"},
    {value:'timezone5',label:  "UTC - 8:00 (Pacific Standard Time)"},
    {value:"timezone6",label:  "UTC - 7:00 (Mountain Standard Time)"},
    {value:'timezone7',label:  "UTC - 6:00 (Central Standard Time)"},
    {value:"timezone8",label:  "UTC - 5:00 (Eastern Standard Time)"},
    {value:'timezone9',label:  "UTC - 4:00  (Atlantic Standard Time)"},
    {value:"timezone10",label: "UTC - 3:00 (Brasília Time)"},
    {value:'timezone11',label: "UTC - 2:00 (Fernando de Noronha Time)"},
    {value:"timezone12",label: "UTC - 1:00 (Azores Time)"},
    {value:'timezone13',label: "UTC (Coordinated Universal Time)"},
    {value:"timezone14",label: "UTC + 1:00 (Central European Time)"},
    {value:'timezone15',label: "UTC + 2:00 (Eastern European Time)"},
    {value:"timezone16",label: "UTC + 3:00 (Moscow Standard Time)"},
    {value:'timezone17',label: "UTC + 3:30 (Iran Standard Time)"},
    {value:"timezone18",label: "UTC + 4:00 (Gulf Standard Time)"},
    {value:'timezone19',label: "UTC + 4:30 (Afghanistan Time)"},
    {value:"timezone20",label: "UTC + 5:00 (Pakistan Standard Time)"},
    {value:'timezone21',label: "UTC + 5:30 (India Standard Time)"},
    {value:"timezone22",label: "UTC + 5:45 (Nepal Time)"},
    {value:'timezone23',label: "UTC + 6:00 (Bangladesh Standard Time)"},
    {value:"timezone24",label: "UTC + 6:30 (Cocos Islands Time)"},
    {value:'timezone25',label: "UTC + 7:00 (Indochina Time)"},
    {value:"timezone26",label: "UTC + 8:00 (China Standard Time)"},
    {value:'timezone27',label: "UTC + 8:45 (Australian Central Western Standard Time)"},
    {value:"timezone28",label: "UTC + 9:00 (Japan Standard Time)"},
    {value:'timezone29',label: "UTC + 9:30 (Australian Central Standard Time)"},
    {value:"timezone30",label: "UTC + 10:00 (Australian Eastern Standard Time)"},
    {value:'timezone31',label: "UTC + 10:30 (Lord Howe Standard Time)"},
    {value:"timezone32",label: "UTC + 11:00 (Norfolk Island Time)"},
    {value:'timezone33',label: "UTC + 12:00 (New Zealand Standard Time)"},
    {value:"timezone34",label: "UTC + 12:45 (Chatham Island Standard Time)"},
    {value:'timezone35',label: "UTC + 13:00 (Tonga Standard Time)"}
  ]);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  let navigate = useNavigate();
  const handleBackClick = () => {
     navigate('/');
  }
  const handleFormClick = () => {
    navigate('/form', { state: { passChosen } });
 }
  
  return(
    <div className="thewhole">
     <div className="circle-icon-container" onClick={()=>handleBackClick()}>
      <FontAwesomeIcon icon={faHome} className="arrowLeft"/>
    </div>
     <div className="calen-container">
     <div className="calen-text-container">
       <h1 className="h1TitleCal">AutoSync Solutions Call</h1>
       <div className="h3InsideContainer">
       <div className="h3Container">
       <h3>
         <FontAwesomeIcon icon={faClock} /> 15 mins
       </h3>
       <h3 className="date-container">
      <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
      <span>{month} {selectedDay}, {year}</span>
      {/* {day} */}
    </h3>
    <h3>
         <FontAwesomeIcon icon={faGlobe} /> {selectedTimeZone.label ? selectedTimeZone.label :''}
       </h3>
       <button className="book-button" onClick={()=>handleFormClick()}>
          Book
        </button>
       </div>
       </div>
     </div>
     <div className="calendarPart">
        <ActualCal 
         setDay={setDay}
         month={month} setMonth={setMonth}
         year={year}   setYear={setYear} 
         date={date}   setDate={setDate}
         timezones={timezones} setTimezones={setTimezones}
         selectedTimeZone={selectedTimeZone} setSelectedTimeZone={setSelectedTimeZone}
         setpassChosen={setpassChosen} selectedDay={selectedDay} setSelectedDay={setSelectedDay}/>
     </div>
   </div>
   
    </div>
  
  ) 
};


export default FullCalendar;