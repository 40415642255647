import React, { useEffect, useState,useRef } from 'react';
import "./cc.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck,  faPhoneAlt, faMagnifyingGlass,   faPenFancy, faEnvelopeCircleCheck, faPencilSquare, faHammer, faInfinity,  faSync,  faBarChart } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

const Completecycle = () => {
  const [activeCard, setActiveCard] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const cyclecards = document.querySelectorAll('.cyclecard');
      const viewportHeight = window.innerHeight;
      const cardPositions = [];

      cyclecards.forEach((card, index) => {
        const rect = card.getBoundingClientRect();
        const topOffset = rect.top;

        // Calculate the position of the card relative to the viewport
        const position = topOffset >= 0 && topOffset < viewportHeight ? topOffset : viewportHeight;

        cardPositions[index] = position;
      });

      // Find the index of the card with the minimum position value
      const minPositionIndex = cardPositions.indexOf(Math.min(...cardPositions));

      setActiveCard(minPositionIndex);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;

      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
  return (
    <div className="allCompleteCycle">
      <div className="centerCC">
       <div className={`sectionCC ${isVisible1 ? 'visibleCC' : ''}`} ref={section1Ref}>
      <h1 className="automatingH1"><FormattedMessage id="sec2Title" /></h1>
      <p className="automatingP">
      <FormattedMessage id="sec2P" />
</p>
</div>
</div><div className="timeline">
        <div className="outer">
          <div className={`cyclecard ${activeCard === 0 ? 'active' : ''}`}>
            <div className={`info ${activeCard === 0 ? 'active' : ''}`}>
            <div className="icon-container">
  <FontAwesomeIcon icon={faCalendarCheck} className="icon" id="calendarIcon"/>
  <div className="connector"></div>
  <FontAwesomeIcon icon={faPhoneAlt} className="icon" id="phoneIcon"/>
</div>

              {/* <FontAwesomeIcon icon={faCalendarAlt}/>
              <FontAwesomeIcon icon={faCalendarTimes}/>
              <FontAwesomeIcon icon={faCalendarMinus}/>
              <FontAwesomeIcon icon={faCalendarPlus}/>
              <FontAwesomeIcon icon={faCalendarWeek}/> */}
              {/* <FontAwesomeIcon icon={faPhone}/> */}
             
              <h3 className={`title ${activeCard === 0 ? 'active' : ''}`} id="cardH3s">
              <FormattedMessage id="sec2Card1T" />
                </h3>
              <p className="cardPs">
              <FormattedMessage id="sec2Card1P" />
              </p>
            </div>
          </div>
          <div className={`cyclecard ${activeCard === 1 ? 'active' : ''}`}>
            <div className={`info ${activeCard === 1 ? 'active' : ''}`}>
           
<div className="icon-container">
  <FontAwesomeIcon icon={faEnvelopeCircleCheck} className="icon" id="pumpIcon"/>
  <div className="connector"></div>
  <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" id="pumpIcon"/>
  <div className="connector"></div>
  <FontAwesomeIcon icon={faPenFancy} className="icon" id="pumpIcon" />
</div>
              <h3 className={`title ${activeCard === 1 ? 'active' : ''}`} id="cardH3s">  <FormattedMessage id="sec2Card2T" /> </h3>
              <p className="cardPs">
                <FormattedMessage id="sec2Card2P" />  </p>
            </div>
          </div>
          <div className={`cyclecard ${activeCard === 2 ? 'active' : ''}`}>
            <div className={`info ${activeCard === 2 ? 'active' : ''}`}>
            <div className="circle-container">
  <div className="circle">
  <FontAwesomeIcon icon={faHammer} className="Hammericon" />
    <FontAwesomeIcon icon={faPencilSquare} className="Pencilicon" />
  </div>
</div>
              <h3 className={`title ${activeCard === 2 ? 'active' : ''}`} id="cardH3s">  <FormattedMessage id="sec2Card3T" /></h3>
              <p className="cardPs">
              <FormattedMessage id="sec2Card3P" /> </p>
            </div>
          </div>
          <div className={`cyclecard ${activeCard === 3 ? 'active' : ''}`}>
            <div className={`info ${activeCard === 3 ? 'active' : ''}`}>
            <div className="circle-container">
            <div className="composite-icon">
  <FontAwesomeIcon icon={faInfinity} className="fa-infinity" />
  <FontAwesomeIcon icon={faSync} className="fa-sync" />
</div></div>
              <h3 className={`title ${activeCard === 3 ? 'active' : ''}`} id="cardH3s"> <FormattedMessage id="sec2Card4T" /></h3>
              <p className="cardPs">
              <FormattedMessage id="sec2Card4P" /> </p>
            </div>
          </div>
          <div className={`cyclecard ${activeCard === 4 ? 'active' : ''}`}>
            <div className={`info ${activeCard === 4 ? 'active' : ''}`}>
            <div className="circle-container">
            <div className="circled">
  <FontAwesomeIcon icon={faBarChart} className="bar-chart-icon" />
</div></div>{/* <FontAwesomeIcon icon={faRocket}/> */}
              <h3 className={`title ${activeCard === 4 ? 'active' : ''}`} id="cardH3s"> <FormattedMessage id="sec2Card5T" /></h3>
              <p className="cardPs">
                 <FormattedMessage id="sec2Card5P" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Completecycle.propTypes = {};

Completecycle.defaultProps = {};

export default Completecycle;