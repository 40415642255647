import React, { useEffect, useState,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faRocket, faVrCardboard, faBrain, faPuzzlePiece, faComment, faLink, faBriefcase, faDesktop } from '@fortawesome/free-solid-svg-icons';
import "./services.css"
import '../../index.css';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { FormattedMessage } from 'react-intl';
const Services = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;
      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
  return (
    <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="section-header text-center">
          <div className={`sectionCC ${isVisible1 ? 'visibleCC' : ''}`} ref={section1Ref}>
          <h2 className="font-bold text-3xl">
          <FormattedMessage id="sec4Title1" /> 
              <span className="text-blue-500">  <FormattedMessage id="sec4Title2" />  </span> <FormattedMessage id="sec4Title3" />
            </h2>
            <p className="sec-icon">
              <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            </p>
          </div>
          </div>
        </div>
        <div className="row mt-5 mt-md-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 justify-center">
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faRocket} />
              </div>
              <h3><FormattedMessage id="sec4Card1T" /> </h3>
              <p>
              <FormattedMessage id="sec4Card1P" />
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faVrCardboard} />
              </div>
              <h3><FormattedMessage id="sec4Card2T" /></h3>
              <p>
              <FormattedMessage id="sec4Card2P" />
               </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faBrain} />
              </div>
              <h3><FormattedMessage id="sec4Card3T" /></h3>
              <p>
              <FormattedMessage id="sec4Card3P" />
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faPuzzlePiece} />
              </div>
              <h3><FormattedMessage id="sec4Card4T" /></h3>
              <p>
              <FormattedMessage id="sec4Card4P" />
             </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faComment} />
              </div>
              <h3><FormattedMessage id="sec4Card5T" /></h3>
              <p>
              <FormattedMessage id="sec4Card5P" />
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faLink} />
              </div>
              <h3><FormattedMessage id="sec4Card6T" /></h3>
              <p>
              <FormattedMessage id="sec4Card6P" />
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faChrome} />
              </div>
              <h3><FormattedMessage id="sec4Card7T" /></h3>
              <p>
              <FormattedMessage id="sec4Card7P" />
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faBriefcase} />
              </div>
              <h3><FormattedMessage id="sec4Card8T" /></h3>
              <p>
              <FormattedMessage id="sec4Card8P" />
              </p>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={faDesktop} />
              </div>
              <h3><FormattedMessage id="sec4Card9T" /></h3>
              <p>
              <FormattedMessage id="sec4Card9P" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Services.propTypes = {};

Services.defaultProps = {};

export default Services;
