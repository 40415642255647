import React from 'react';
import PropTypes from 'prop-types';
import { useForm, ValidationError } from '@formspree/react';
import { useNavigate,useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome} from '@fortawesome/free-solid-svg-icons'
import "./form.css"
const Form = () => {
  const [state, handleSubmit] = useForm("xoqgryav");
  const location = useLocation();
  const timeChosen = location.state?.passChosen || "";
  let navigate = useNavigate();
  const handleBackClick = () => {
   
     navigate('/');
  }
  if (state.succeeded) {
      return <><div className="circle-icon-container" onClick={()=>handleBackClick()}> <FontAwesomeIcon icon={faHome} className="arrowLeft"/> </div> <div className="Messagecontainer"> <p className="message">Your message has been sent! We will contact you by email.</p> </div></>  
    }
  return(
    <div className="Formcontainer">
    <form onSubmit={handleSubmit} className="contact-form">
    <h1 class="final-form-title">Final Form</h1>
      <label htmlFor="fullName" className="form-label">
        Full Name
      </label>
      <input
        id="fullName"
        type="text" 
        name="fullName"
        className="form-input"
      />
      <label htmlFor="email" className="form-label">
        Email Address
      </label>
      <input
        id="email"
        type="email" 
        name="email"
        className="form-input"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
        className="form-error"
      />
      
      <label htmlFor="message" className="form-label">
        Description of project
      </label>
      <textarea
        id="message"
        name="message"
        className="form-textarea"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
        className="form-error"
      />
       <label htmlFor="time" className="form-label time">
      Time
      </label>
      <input
        id="time"
        type="text" 
        name="time"
        className="form-input time"
        defaultValue={timeChosen}
      />
      <button type="submit" disabled={state.submitting} className="form-button">
        Submit
      </button>
     
    </form>
    </div>

);
}
Form.propTypes = {};

Form.defaultProps = {};

export default Form;
