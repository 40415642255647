import React from 'react';
import PropTypes from 'prop-types';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FormattedMessage } from 'react-intl';
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p className="footer-motto"><FormattedMessage id="sec8T1" /></p>
        <p className="footer-text">&copy; <FormattedMessage id="sec8T2" /></p>
        <p className="footer-text" id="connectionP"><FormattedMessage id="sec8T3" />
       
         <a className="footer-link" href="https://www.linkedin.com/in/hidaya-gharad-85066722b/" target="_blank" style={{textDecoration:"none"}}>
         <br />
         <span style={{paddingRight:"10px"}}>LinkedIn</span>
         <FontAwesomeIcon icon={faLinkedin}/>
            </a></p>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;