import React, { useEffect, useRef, useState } from 'react';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import Main from '../Main/Main';
import About from '../About/About';
import Completecycle from '../Completecycle/Completecycle';
import Services from '../Services/Services';
import Pricing from '../Pricing/Pricing';
import FAQ from '../FAQ/FAQ';
import Projects from '../Projects/Projects';
import Careers from '../Careers/Careers';
import DescAlt from '../DescAlt/DescAlt';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import "./try.css"
const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showCustomIcon, setShowCustomIcon] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowCustomIcon(window.innerWidth < 1082);
      setShowMenu(false);  // Hide the menu on resize
    };
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Call handleResize on component mount
    handleResize();
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    if(window.innerWidth < 1082){
      setShowMenu(!showMenu);
    }
  
  };

  return (
    <nav> 
      <div className ="mightGrp" >
     
         {showCustomIcon && (
        <>
        <img
  className="logoImg2"
  src="./pictures/logo.png"
  alt="Language"
  />
            <div className={`custom-icon ${showMenu ? 'open' : ''}`} onClick={toggleMenu}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
        </>
      )}
      <div>
      <ul className={`menu ${showMenu ? 'show' : 'dontShow'}`}>
        <li className={showCustomIcon ? 'showImg' : ''}>    
        </li>
        {!showCustomIcon && (
  <li className={showCustomIcon ? 'show' : ''}>
  <Link to="main" smooth={true} duration={500} onClick={toggleMenu}>
  <img
  className="logoImg"
  src="./pictures/logo.png"
  alt="Language"
  />
  </Link>
  </li>
        )}
      
        <li className={showCustomIcon ? 'show' : ''} style={{marginTop:"30px"}}>
          <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
         <FormattedMessage id="about" />
          </Link>
        </li>
        <li className={showCustomIcon ? 'show' : ''} style={{ marginTop: showCustomIcon ? "-9px" : "30px" }}>
          <Link to="complete-cycle" smooth={true} duration={500} onClick={toggleMenu}>
          <FormattedMessage id="HOW" />
          </Link>
        </li>
        <li className={showCustomIcon ? 'show' : ''} style={{ marginTop: showCustomIcon ? "-9px" : "30px" }}>
          <Link to="services" smooth={true} duration={500} onClick={toggleMenu}>
          <FormattedMessage id="Services" /> 
          </Link>
        </li>
        <li className={showCustomIcon ? 'show' : ''} style={{ marginTop: showCustomIcon ? "-9px" : "30px" }}>
          <Link to="pricing" smooth={true} duration={500} onClick={toggleMenu}>
          <FormattedMessage id="Pricing" /> 
          </Link>
        </li>
        <li className={showCustomIcon ? 'show' : ''} style={{ marginTop: showCustomIcon ? "-9px" : "30px" }}>
          <Link to="faq" smooth={true} duration={500} onClick={toggleMenu}>
          <FormattedMessage id="FAQ" /> 
          </Link>
        </li>
        <li className={showCustomIcon ? 'show' : ''} style={{ marginTop: showCustomIcon ? "-9px" : "30px" }}>
          <Link to="projects" smooth={true} duration={500} onClick={toggleMenu}>
          <FormattedMessage id="sec9Title" />
          </Link>
        </li>
        <li className={showCustomIcon ? 'show' : ''} style={{ marginTop: showCustomIcon ? "-9px" : "30px" }}>
          <Link to="careers" smooth={true} duration={500} onClick={toggleMenu}>
          <FormattedMessage id="Careers" /> 
          </Link>
        </li>
       
      </ul>
      </div>
      </div>
    </nav>
  );
};

const Try = ({ onLanguageSelection }) =>{
  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('myHeader');
      const page = document.getElementById('page');

      page.classList.remove('menuopen');
      if (window.scrollY >= 100) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    };

    const handleMenuButtonClick = () => {
      const header = document.getElementById('myHeader');
      const page = document.getElementById('page');

      header.classList.remove('sticky');
      page.classList.add('menuopen');
    };

    const handleLinkClick = (event) => {
      event.preventDefault();

      const targetId = event.target.getAttribute('href');
      const targetElement = document.querySelector(targetId);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    const openMenuButton = document.getElementById('openmenu');
    openMenuButton.addEventListener('click', handleMenuButtonClick);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      links.forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
      openMenuButton.removeEventListener('click', handleMenuButtonClick);
    };
  }, []);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isLessThan900 = window.innerWidth < 1082;
      setShowMenu(isLessThan900);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call the handleResize function initially
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    // Scroll to the 'Main' component
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  let navigate = useNavigate();
  const handleBackClick = () => {
     navigate('/c');
  }
  const [showPopup, setShowPopup] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    // Check if the user has already selected a language
    // const userLanguage = localStorage.getItem('selectedLanguage');
    // if (userLanguage) {
    //   setSelectedLanguage(userLanguage);
    //   setShowPopup(true);
    // }
  }, []);

  const handleLanguageSelect = (language) => {
    onLanguageSelection(language);
    setSelectedLanguage(language);
    setShowPopup(false);
  };
  return (
    <>
    {showPopup && (
        <div className="language-popup-container">
          <div className="language-popup">
            <h2>Choose your language</h2>
            <div className="language-buttons">
              <button onClick={() => handleLanguageSelect('ar')}>العربية</button>
              <button onClick={() => handleLanguageSelect('en')}>English</button>
            </div>
          </div>
        </div>
      )}
      {showMenu && <Menu />}
      <div 
      className="" 
      style=
      {{backgroundImage: "url('./pictures/background.jpg')"}}
    >
      <header id="myHeader" className="tryHeader" >
      {!showMenu &&  <Link to="main" smooth={true} duration={500} onClick={handleClick}>
        <img
          id="logo"
          src="./pictures/logo1.png"
          alt="Language"
        />
      </Link>}
        
{!showMenu && 
  <nav>
  {/* <a href="#main"></a> */}
  <a href="#about"><FormattedMessage id="about" /></a>
  <a href="#complete-cycle"><FormattedMessage id="HOW" /></a>
  <a href="#services"><FormattedMessage id="Services" /></a>
  <a href="#pricing"><FormattedMessage id="Pricing" /></a>
  <a href="#faq"><FormattedMessage id="FAQ" /></a>
  <a href="#projects"><FormattedMessage id="sec9Title" /></a>
  <a href="#careers"><FormattedMessage id="Careers" /></a>
  <button id="openmenu">
    <span></span>
    <span></span>
  </button>
  </nav>
}
      </header>
      
       <Main id="main" language={selectedLanguage} />
 
      <div id="page" className="">
        <section className="trySection">
          <Element name="about" className="element" id="about" >
            <About  id="about" />
         </Element> 
        </section>
        <section className="trySection" id="complete-cycle">
        <Element name="complete-cycle" className="element">
           <Completecycle />
        </Element>
        </section>
        <section className="trySection" id="services">
        <Element name="services" className="element">
          <DescAlt />
          <Services />
        </Element>
        </section>
        <section className="trySection" id="pricing">
            <Element name="pricing" className="element">
            <Pricing />
          </Element>
        </section>
        <section className="trySection" id="faq">
          <Element name="faq" className="element">
            <FAQ />
          </Element>
        </section>
    
        <section className="trySection" id="projects">
          <Element name="projects" className="element">
          <Projects />
          </Element>
        </section>
        <section className="trySection" id="careers"
        >
          <Element name="careers" className="element">
        <Careers />
      </Element>
        </section>
        <Footer />
      </div>
      <a id="fixedlink" onClick={()=>handleBackClick()}>
        » <FormattedMessage id="BookDemo" />
      </a>
    </div>
    </>
  );
};
Try.propTypes = {};

Try.defaultProps = {};

export default Try;



