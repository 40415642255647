import React, { useState,useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import "./projects.css";
import { FormattedMessage } from 'react-intl';
const Projects = () => {
  const [isHovered, setIsHovered] = useState([false, false, false, false]);
  const [isActive, setIsActive] = useState([false, false, false, false]);

  const handleMouseEnter = (index) => {
    setIsHovered((prevIsHovered) => {
      const updatedHovered = [...prevIsHovered];
      updatedHovered[index] = true;
      return updatedHovered;
    });
  };

  const handleMouseLeave = (index) => {
    setIsHovered((prevIsHovered) => {
      const updatedHovered = [...prevIsHovered];
      updatedHovered[index] = false;
      return updatedHovered;
    });
  };

  const handleToggleActive = (index) => {
    setIsActive((prevIsActive) => {
      const updatedActive = [...prevIsActive];
      updatedActive[index] = !updatedActive[index];
      return updatedActive;
    });
  };
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;

      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
  return (
    <>
       <div className={`sectionCC ${isVisible1 ? 'visibleCC' : ''}`} ref={section1Ref}>
         <h1 style={{ textAlign: 'center',marginBottom:"30px" }} className="automatingH1"> <FormattedMessage id="sec9Title" /> </h1>
         </div>
         <div className="cards-wrap-container">
      <div className="cards-container">
      <div
        className={`card top-card ${isHovered[0] || isActive[0] ? 'active' : ''}`} >
     
         <p className="titleProject"><FormattedMessage id="sec9Card1T" /></p>
         <img
        className="card-image"
        src={'./pictures/aluminumProducts.jpg'}
        alt="Language"
      />
       
      </div>
      <div className={`card bottom-card ${isHovered[0] || isActive[0] ? 'active' : ''}`} 
         onMouseEnter={() => handleMouseEnter(0)}
         onMouseLeave={() => handleMouseLeave(0)}
         onClick={() => handleToggleActive(0)}>
      {isHovered[0] || isActive[0] ? (
        <>
    <p className="lessOne" style={{zIndex:"999999999999"}}><FormattedMessage id="sec9Less" /></p>
    <p className="paragraphProject"><FormattedMessage id="sec9Card1P" /></p>
        </>
  ) : (
       <p className="moreOne"><FormattedMessage id="sec9More" /></p>
  )}
 
      </div>
      </div>
      <div className="cards-container">
      <div
        className={`card top-card ${isHovered[1] || isActive[1] ? 'active' : ''}`}
     
      >
         <p className="titleProject"><FormattedMessage id="sec9Card2T" /></p>
         <img
        className="card-image"
        src={'./pictures/hotel.jpg'}
        alt="Language"
      />
      </div>
      <div className={`card bottom-card ${isHovered[1] || isActive[1] ? 'active' : ''}`}
         onMouseEnter={() => handleMouseEnter(1)}
         onMouseLeave={() => handleMouseLeave(1)}
         onClick={() => handleToggleActive(1)}>
      {isHovered[1] || isActive[1] ? (
        <>
          <p className="lessOne" style={{zIndex:"999999999999"}}><FormattedMessage id="sec9Less" /></p>
          <p className="paragraphProject"><FormattedMessage id="sec9Card2P" /></p>
     
        </>
  ) : (
    <p className="moreOne"><FormattedMessage id="sec9More" /></p>
  )}
      </div>
      </div>
      <div className="cards-container">
      <div
        className={`card top-card ${isHovered[2] || isActive[2] ? 'active' : ''}`}
      
      >
         <p className="titleProject"><FormattedMessage id="sec9Card3T" /></p>
         <img
        className="card-image"
        src={'./pictures/office.jpg'}
        alt="Language"
      />
       
      </div>
      <div className={`card bottom-card ${isHovered[2] || isActive[2] ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(2)}
        onMouseLeave={() => handleMouseLeave(2)}
        onClick={() => handleToggleActive(2)}>
      {isHovered[2] || isActive[2] ? (
        <>
        <p className="lessOne" style={{zIndex:"999999999999"}}><FormattedMessage id="sec9Less" /></p>
          <p className="paragraphProject"><FormattedMessage id="sec9Card3P" /></p>
           
        </>
  ) : (
    <p className="moreOne"><FormattedMessage id="sec9More" /></p>
  )}
      </div>
      </div>
       <div className="cards-container">
      <div
        className={`card top-card ${isHovered[3] || isActive[3] ? 'active' : ''}`}
      
      >
        <p className="titleProject"><FormattedMessage id="sec9Card4T" /></p>
           <img
        className="card-image"
        src={'./pictures/construction.jpg'}
        alt="Language"
      />
      </div>
      <div className={`card bottom-card ${isHovered[3] || isActive[3] ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(3)}
        onMouseLeave={() => handleMouseLeave(3)}
        onClick={() => handleToggleActive(3)}>
      {isHovered[3] || isActive[3] ? (
        <>
         <p className="lessOne" style={{zIndex:"999999999999"}}><FormattedMessage id="sec9Less" /></p>
         <p className="paragraphProject"><FormattedMessage id="sec9Card4P" /></p>
        </>
  ) : (
    <p className="moreOne"><FormattedMessage id="sec9More" /></p>
  )}
      </div>
      </div>
    </div>
    </>
    
  );
}

export default Projects;