import React,{ useEffect } from 'react';
import PropTypes from 'prop-types';
import "./descAlt.css"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FormattedMessage } from 'react-intl';
const DescAlt = () => {
  useEffect(() => {
    if (!CSS.supports('animation-timeline: scroll()')) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.to('.decsContent', {
        scrollTrigger: {
          trigger: 'body',
          scrub: 0.5,
          start: 'top top',
          end: window.innerHeight * 0.4,
        },
        scale: 1,
      });
      gsap.to('.fillers path', {
        scrollTrigger: {
          trigger: '.decsContent',
          scrub: 0.5,
          ease: 'power4.in',
          start: 'top bottom+=20%',
          end: 'bottom bottom-=50%',
        },
        strokeDashoffset: 0,
      });
    }
  }, []);
  return(
  <div >
    <main className="descAltMain">
      <section className="descAltSection">
        <h1 className="descAltH1"> <FormattedMessage id="sec3Title" /></h1>
      </section>
      <div className="decsContent">
        <svg viewBox="0 0 1440 4096" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g className="backers">
          <path d="M-3317 96H387c276.142 0 500 223.858 500 500v1064.51c0 99.41-80.589 180-180 180H434.99c-99.412 0-180.001 80.58-180.001 180V4248" stroke="red" strokeWidth="100" strokeLinecap="round"/>
            <path d="M4379 804H1387c-276.14 0-499.997 223.86-499.997 500v356.51c0 99.41-80.589 180-180 180H434.991c-99.411 0-180 80.59-180 180V4248" stroke="red" strokeWidth="100" strokeLinecap="round"/>
            <path d="M4423 96H1387.02c-276.14 0-500.001 223.858-500.001 500.001V1660.51c0 99.41-80.589 180-180 180H434.995c-99.411 0-180 80.59-180 180l.001 2227.49" stroke="red" strokeWidth="100" strokeLinecap="round"/> </g>
          <g className="fillers">
          <path d="M-3317 96H387c276.142 0 500 223.858 500 500v1064.51c0 99.41-80.589 180-180 180H434.99c-99.412 0-180.001 80.58-180.001 180V4248" stroke="red" strokeWidth="100" strokeLinecap="round"/>
            <path d="M4379 804H1387c-276.14 0-499.997 223.86-499.997 500v356.51c0 99.41-80.589 180-180 180H434.991c-99.411 0-180 80.59-180 180V4248" stroke="red" strokeWidth="100" strokeLinecap="round"/>
            <path d="M4423 96H1387.02c-276.14 0-500.001 223.858-500.001 500.001V1660.51c0 99.41-80.589 180-180 180H434.995c-99.411 0-180 80.59-180 180l.001 2227.49" stroke="red" strokeWidth="100" strokeLinecap="round"/> </g>
        </svg>
        <section className="descAltSection2">
          <h3 className="descAltH2"><FormattedMessage id="sec3P1" /></h3>
        </section>
        <section className="descAltSection2">
          <h2 className="descAltH2"><FormattedMessage id="sec3P2" /></h2>
        </section >
        <section className="descAltSection2" id="sectionID2">
          <h2 className="descAltH2 longone"><FormattedMessage id="sec3P3" /></h2>
        </section>
        <section className="descAltSection2" id="sectionID2">
          <h2 className="descAltH2"><FormattedMessage id="sec3P4" /></h2>
        </section>
      </div>
    </main>
  </div>
);}

DescAlt.propTypes = {};

DescAlt.defaultProps = {};

export default DescAlt;
