import React,{useState,useEffect,useRef} from 'react';
import "./about.css"
import { FormattedMessage } from 'react-intl';

const About = ({language}) => {
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;

      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
    return(
      <div className={`sectionAbout ${isVisible1 ? 'visibleAbout' : ''}`} ref={section1Ref}>
      <div className="allAbout">
        <div className="centerContainer">
          <h1 className="automatingH1"><FormattedMessage id="sec1Title" /></h1>
          <p className="automatingPa"><FormattedMessage id="sec1P" /></p>
       </div>
      <section id="aboutSection">  
        <div className="abtcard"> 
          <h2 className="automatingH2"><FormattedMessage id="sec1Card1T" /></h2>
          <p id="abtP"><FormattedMessage id="sec1Card1P" /></p>
        </div>
        <div className="abtcard">
          <h2 className="automatingH2"><FormattedMessage id="sec1Card2T" /></h2>
          <p id="abtP"><FormattedMessage id="sec1Card2P" /></p>
      
        </div>
        <div className="abtcard">
          <h2 className="automatingH2"><FormattedMessage id="sec1Card3T" /></h2>
          <p id="abtP"><FormattedMessage id="sec1Card3P" /></p>
        </div>
      </section>
      </div>
      </div>
    );
}

About.propTypes = {};

About.defaultProps = {};

export default About;
