import { useState ,useRef,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './faq.css'; // Import the CSS file for styling
import { FormattedMessage } from 'react-intl';
const Faq = () => {
  const [faqItems, setFaqItems] = useState([
    {
      question: <FormattedMessage id="sec6Card1Q"/>,
      answer: <FormattedMessage id="sec6Card1A"/>,
      isOpen: false,
      
    },
    {
      question: <FormattedMessage id="sec6Card2Q"/>,
      answer: <FormattedMessage id="sec6Card2A"/>,
      isOpen: false,
    },
    // {
    //   question: 'How long does it take to complete a development task?',
    //   answer: 'The timeframe for completing your software depends on the task\'s complexity. Typically, most task requests are fulfilled within a quick turnaround of 24 to 72 hours. However, more intricate tasks may require additional time for completion.\nTo facilitate efficient tracking, you will be utilizing weekly "Sprints." These sprints consist of multiple prioritized tasks that our developers aim to complete within the week. Any unfinished tasks at the end of a sprint will automatically roll over to the following week\'s sprint.\nHere\'s how our process works: An active project is divided into essential building blocks known as "tasks." Multiple tasks are carefully selected and assembled into a weekly sprint, representing 3 to 6 tasks to be accomplished within that week. The number of tasks assigned to a sprint varies based on the complexity and specificity of your request.\nHere are a few examples of project timelines to provide you with an idea: \n* Large Projects (3-5 Months): "Develop a comprehensive CRM dashboard integrated with an AI Chatbot for a business."\n* Medium Projects (1-3 Months): "Create a SAAS platform that offers copywriters an advanced AI text editor."\n* Small Projects (2-3 Weeks): "Build a basic AI Chatbot system."\nPlease note that these examples are general and the number of tasks may vary depending on the complexity of your specific requests. Our dedicated team is committed to delivering high-quality work, and we will keep you informed about the progress of your project throughout the development process.',
    //   isOpen: false,
    // },
    {
      question:<FormattedMessage id="sec6Card3Q"/>,
      answer:<FormattedMessage id="sec6Card3A"/>,
      isOpen:false
    },
    {
      question:<FormattedMessage id="sec6Card4Q"/>,
      answer:<FormattedMessage id="sec6Card4A"/>,
      isOpen:false
    },
    {
      question:<FormattedMessage id="sec6Card5Q"/>,
      answer:<FormattedMessage id="sec6Card5A"/>,
      isOpen:false
    }
  ]);

  const toggleFaq = (index) => {
  
    setFaqItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            isOpen: !item.isOpen,
          };
        }
        return item;
      });
      return updatedItems;
    });
  
  };
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;

      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
  return (
    <div className="faq-container">
       <div className={`sectionCC ${isVisible1 ? 'visibleCC' : ''}`} ref={section1Ref}>
         <h1 style={{ textAlign: 'center',marginBottom:"30px" }} className="automatingH1"><FormattedMessage id="sec6Title"/></h1>
         </div>
      {faqItems.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFaq(index)}>
            <div className={`faq-item2  ${item.isOpen ? 'opened' : ''}`}>
              <span>{item.question}</span>
              <FontAwesomeIcon
                icon={item.isOpen ? faAngleUp : faAngleRight}
                className={`faq-icon ${item.isOpen ? 'opened' : ''}`}
              />
            </div>
          </div>
          {item.isOpen
           &&   <div className="faq-answer">{item.answer}</div>}
          <hr className="faq-divider" />
        </div>
      ))}
    </div>
  );
};

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
