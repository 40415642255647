import {  BrowserRouter, Routes,Route,Navigate} from "react-router-dom";
import React, { useState, useEffect, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import Home from './components/Home/Home';
import Try from './components/Try/Try';
import FullCalendar from './components/Calendar/Calendar';
import DescAlt from './components/DescAlt/DescAlt';
import Form from './components/Form/Form';
import Parallax from './components/parallax/parallax';
import arMessages from './jsons/ar.json';
import enMessages from './jsons/en.json';
function App() {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en');
  const messages = {
    en: enMessages,
    ar: arMessages,
 };
 function handleLanguageSelection(languageCode) {
  setLocale(languageCode);
  localStorage.setItem('locale', languageCode);
}
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
    <BrowserRouter>
      <Routes>
         <Route exact path="/" element={<Try  onLanguageSelection={handleLanguageSelection} />} />
         <Route exact path="/form" element={<Form />} />
         <Route exact path="/t" element={<Try />} />
         <Route exact path="/d" element={<DescAlt />} />
         <Route exact path="/c" element={<FullCalendar />} />
         <Route exact path="/p" element={<Parallax />} />
      </Routes>
    </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
