import React, { useEffect, useState,useRef } from 'react';
import PropTypes from 'prop-types';
import "./careers.css"
import { FormattedMessage } from 'react-intl';
const Careers = () =>{
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;

      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
  return (
    <div className="allCareers">
         <div className={`sectionCC ${isVisible1 ? 'visibleCC' : ''}`} ref={section1Ref}>
         <h1 style={{ textAlign: 'center' }} className="automatingH1"> <FormattedMessage id="sec7Title" /></h1>
         </div>
 
    <div className="content">
    <div className="marquee">
      <div className="marquee_blur" aria-hidden="true">
        <p className="marquee_text"><FormattedMessage id="sec7P" /></p>
      </div>
      <div className="marquee_clear">
        <p className="marquee_text"><FormattedMessage id="sec7P" /></p>
      </div>
    </div>
  
    <p className="text">
     &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
     </p>
  </div>
    </div>
 
  
);}

Careers.propTypes = {};

Careers.defaultProps = {};

export default Careers;
