import React,{useState,useRef,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faClock, faGift, faIndustry } from '@fortawesome/free-solid-svg-icons';
import './pricing.css';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const Pricing = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  
  const section1Ref = useRef(null);
  

useEffect(() => {
  const handleScroll = () => {
    if (section1Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const isVisible1 = section1Top < window.innerHeight;

      setIsVisible1(isVisible1);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
let navigate = useNavigate();
const handleCalendarClick = () => {
   navigate('/c');
}
  return (

    <div style={{marginTop:"600px"}}>
        <div className={`sectionCC ${isVisible1 ? 'visibleCC' : ''}`} ref={section1Ref}>
         <h1 style={{ textAlign: 'center' }} className="automatingH1"><FormattedMessage id="sec5Title" /></h1>
      </div>
       <div className="pricing-cards">
        {/* <div className="pricing-card">
          <div className="pricing-card-content">
          <div className="icon-circle">
              <FontAwesomeIcon icon={faGift} className="iconsPrice"/>
            </div>
            <h1>Free Tier</h1>
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text">Basic Features</p>
            <span className="x-icon">&#10005;</span>
            <p className="additional-text">Additional information</p>
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text">Basic Features</p>
            <span className="x-icon">&#10005;</span>
            <p className="additional-text">Additional information</p>
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text">Basic Features</p>
            <span className="x-icon">&#10005;</span>
            <p className="additional-text">Additional information</p>
          </div>
        </div> */}
        <div className="pricing-card second">
          <div className="pricing-card-content">
          <h1 className="standard-plan-heading"><FormattedMessage id="sec5Card1H" /></h1>
          <div className="icon-circle second">
              <FontAwesomeIcon icon={faClock} className="iconsPrice second"/>
            </div>
           
            <p className="pricing-tag one"><FormattedMessage id="sec5Card1T" /></p>
            <button className="book-call-button" onClick={()=>handleCalendarClick()} ><FormattedMessage id="sec5Card1B" /></button>
            <hr className="custom-hr" />
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card1P1" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card1P2" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card1P3" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10005;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card1P4" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10005;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card1P5" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card1P6" /></p>
            </div>
          </div>
        </div>
        <div className="pricing-card">
          <div className="pricing-card-content">
          <h1 className="standard-plan-heading"><FormattedMessage id="sec5Card2H" /></h1>
          <div className="icon-circle">
              <FontAwesomeIcon icon={faIndustry} className="iconsPrice"/>
            </div>
           
            {/* <button>Subscribe Now</button> */}
            <p className="pricing-tag"><FormattedMessage id="sec5Card2T1" />
<span className="pricing-tag-small"><FormattedMessage id="sec5Card2T2" /></span>
</p>
            <button className="book-call-button" onClick={()=>handleCalendarClick()}><FormattedMessage id="sec5Card2B" /></button>
            <hr className="custom-hr" />
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card2P1" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card2P2" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card2P3" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card2P4" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card2P5" /></p>
            </div>
            <div id="putTogether">
            <span className="tick-icon">&#10003;</span>
            <p className="pricing-text"><FormattedMessage id="sec5Card2P6" /></p>
            </div>
          </div>
        </div>
      </div>
       
    </div>
  );
};

export default Pricing;