import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import './main.css';

const Main = ({language}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [audioElement, setAudioElement] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(language)
    let audioSrc = './English.m4a';
    if (language === 'ar') {
      audioSrc = './Arabic.m4a';
    }
    const audio = new Audio(audioSrc);
    setAudioElement(audio);

    // Add event listener to detect when audio finishes playing
    audio.addEventListener('ended', () => {
      setIsPlaying(false);
    });

    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('ended', () => {
        setIsPlaying(false);
      });
    };
  }, [language]);
  const handleControlsClick = () => {
    setIsPlaying(!isPlaying);
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
    }
  };

  return (
    <div className="homeMain">
      <img
        className="homeLanguageImage"
        src={screenWidth <= 800 ? './pictures/1Small.png' : './pictures/1.png'}
        alt="Language"
      />

      <FontAwesomeIcon
        icon={isPlaying ? faPause : faPlay}
        className="controls"
        onClick={handleControlsClick}
      />
    </div>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;