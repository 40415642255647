import React, { useEffect, useRef, useState } from 'react';
import "./px.css";

const Parallax = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  
 useEffect(() => {
  const handleScroll = () => {
    // Make sure the refs are not null before accessing the properties
    if (section1Ref.current && section2Ref.current) {
      const section1Top = section1Ref.current.getBoundingClientRect().top;
      const section2Top = section2Ref.current.getBoundingClientRect().top;

      // Checking if sections are in the viewport
      const isVisible1 = section1Top < window.innerHeight;
      const isVisible2 = section2Top < window.innerHeight;

      setIsVisible1(isVisible1);
      setIsVisible2(isVisible2);
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);
  return (
    <div>
      <div className={`section ${isVisible1 ? 'visible' : ''}`} ref={section1Ref} style={{ height: '100vh' }}>
        Section 1
      </div>
      <div className={`section ${isVisible2 ? 'visible' : ''}`} ref={section2Ref} style={{ height: '100vh' }}>
        Section 2
      </div>
    </div>
  );
};

export default Parallax;
